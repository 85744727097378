import Vue from "vue";
import Router, {Route} from "vue-router";
import store from "./store";

Vue.use(Router);

const isAuthenticated = (to: Route, from: Route, next: any) => {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next("/login");
  }
};

const isNotAuthenticated = (to: Route, from: Route, next: any) => {
  if (!store.getters.isAuthenticated) {
    next();
  } else {
    next("/");
  }
};

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      meta: {authorize: []},
      component: () => import(/* webpackChunkName: "notfoundpage" */ "./views/PageNotFound.vue"),
    },
    {
      path: "/",
      redirect: () => {
        if (!store.getters.isAuthenticated) {
          return "/login";
        }
        if (store.getters.checkRight(1)) {
          return "/meetings";
        }
        if (store.getters.checkRight(2)) {
          return "/board-meetings";
        }
        if (store.getters.checkRight(3)) {
          return "/ngs-meetings";
        }
        if (store.getters.checkRight(4)) {
          return "/ngs-steering-group-meetings";
        }
        if (store.getters.checkRight(5)) {
          return "/controlpanel";
        }
      },
    },
    {
      path: "/login",
      meta: {authorize: []},
      name: "login",
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
    },
    {
      path: "/password-reset",
      meta: {authorize: []},
      name: "reset-password-request",
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "password-reset-request" */
        "./views/password-reset/PasswordResetRequestForm.vue"),
    },
    {
      path: "/password-reset/:hash",
      meta: {authorize: []},
      name: "reset-password-form",
      props: true,
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "password-request" */
        "./views/password-reset/PasswordResetForm.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      beforeEnter: isAuthenticated,
      component: () => import(/* webpackChunkName: "profile" */ "./views/Profile.vue"),
    },
    {
      path: "/meetings",
      name: "meetings",
      beforeEnter: isAuthenticated,
      props: {
        meetingType: 1,
        pageTitle: "EuroClonality Meetings",
      },
      component: () => import(/* webpackChunkName: "meetings" */ "./views/Meetings.vue"),
    },
    {
      path: "/board-meetings",
      name: "board-meetings",
      beforeEnter: isAuthenticated,
      props: {
        meetingType: 2,
        pageTitle: "EuroClonality Board Meetings",
      },
      component: () => import(/* webpackChunkName: "meetings" */ "./views/Meetings.vue"),
    },
    {
      path: "/ngs-meetings",
      name: "ngs-meetings",
      beforeEnter: isAuthenticated,
      props: {
        meetingType: 3,
        pageTitle: "EuroClonality-NGS Meetings",
      },
      component: () => import(/* webpackChunkName: "meetings" */ "./views/Meetings.vue"),
    },
    {
      path: "/ngs-steering-group-meetings",
      name: "ngs-steering-group-meetings",
      beforeEnter: isAuthenticated,
      props: {
        meetingType: 4,
        pageTitle: "EuroClonality-NGS Steering Group Meetings",
      },
      component: () => import(/* webpackChunkName: "meetings" */ "./views/Meetings.vue"),
    },
    {
      path: "/progress-monitoring-tool",
      name: "progress-monitoring-tool",
      beforeEnter: isAuthenticated,
      props: {
        pageTitle: "Progress monitoring tool NGS WPs",
      },
      component: () => import(/* webpackChunkName: "progress-monitoring-tool" */ "./views/ProgressMonitoringTool.vue"),
    },
    {
      path: "/controlpanel",
      component: () => import(/* webpackChunkName: "controlpanel" */ "./views/ControlPanel.vue"),
      beforeEnter: isAuthenticated,
      meta: {authorize: 5},
      children: [
        {
          path: "",
          name: "controlpanel",
          redirect: "menu",
        },
        {
          path: "menu",
          name: "controlpanelmenu",
          component: () => import(/* webpackChunkName: "controlpanelmenu" */
            "./views/control-panel/ControlPanelMenu.vue"),
        },
        {
          path: "meetings",
          name: "cp-meetings",
          component: () => import(/* webpackChunkName: "meetingsControlPanel" */
            "./views/control-panel/MeetingsControlPanel.vue"),
          props: {
            meetingType: 1,
            pageTitle: "Edit EuroClonality Meetings",
          },
        },
        {
          path: "board-meetings",
          name: "cp-board-meetings",
          component: () => import(/* webpackChunkName: "meetingsControlPanel" */
            "./views/control-panel/MeetingsControlPanel.vue"),
          props: {
            meetingType: 2,
            pageTitle: "Edit EuroClonality Board Meetings",
          },
        },
        {
          path: "ngs-meetings",
          name: "cp-ngs-meetings",
          component: () => import(/* webpackChunkName: "meetingsControlPanel" */
            "./views/control-panel/MeetingsControlPanel.vue"),
          props: {
            meetingType: 3,
            pageTitle: "Edit EuroClonality-NGS Meetings",
          },
        },
        {
          path: "ngs-steering-group-meetings",
          name: "cp-ngs-steering-group-meetings",
          component: () => import(/* webpackChunkName: "meetingsControlPanel" */
            "./views/control-panel/MeetingsControlPanel.vue"),
          props: {
            meetingType: 4,
            pageTitle: "Edit EuroClonality-NGS Steering Group Meetings",
          },
        },
        {
          path: "protocols",
          name: "cp-protocols",
          component: () => import(/* webpackChunkName: "usersControlPanel" */
            "./views/control-panel/DownloadsControlPanel.vue"),
          props: {
            title: "Edit EuroClonality-NGS protocols",
          },
        },
        {
          path: "users",
          name: "cp-users",
          component: () => import(/* webpackChunkName: "usersControlPanel" */
            "./views/control-panel/UsersControlPanel.vue"),
          props: {
            meetingType: 4,
            pageTitle: "Edit Users",
          },
        },
      ],
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   const authorize = to.meta.authorize || to.matched.find((r) => r.meta.authorize).meta.authorize;

//   if (authorize) {
//     if (authorize.length && !store.getters.checkRole(authorize)) {
//       return next({path: from.path});
//     }
//   }
//   next();
// });
