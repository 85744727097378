import axios from "axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    1: {
      past: [],
      upcoming: [],
    },
    2: {
      past: [],
      upcoming: [],
    },
    3: {
      past: [],
      upcoming: [],
    },
    4: {
      past: [],
      upcoming: [],
    },
  },
  mutations: {
    setUpcomingMeetings(state: any, {meetingType, data}: any) {
      state[meetingType].upcoming = data || [];
    },
    setPastMeetings(state: any, {meetingType, data}: any) {
      state[meetingType].past = data || [];
    },
    addMeeting(state: any, {meetingType, meeting}: any) {
      const meetingDate = meeting.end_date ? meeting.end_date : meeting.start_date;
      if (moment(meetingDate).isSameOrAfter(moment(), "day")) {
        state[meetingType].upcoming.unshift(meeting);
      } else {
        state[meetingType].past.unshift(meeting);
      }
    },
    editMeeting(state: any, {meetingType, meeting}: any) {
      const meetingDate = meeting.end_date ? meeting.end_date : meeting.start_date;
      const isUpcoming = moment(meetingDate).isSameOrAfter(moment(), "day");
      const upcomingIdx = state[meetingType].upcoming.findIndex((m: any) => m.id === meeting.id);
      const pastIdx = state[meetingType].past.findIndex((m: any) => m.id === meeting.id);

      if (upcomingIdx >= 0 && isUpcoming) {
        state[meetingType].upcoming[upcomingIdx] = meeting;
      } else if (upcomingIdx >= 0 && !isUpcoming) {
        state[meetingType].upcoming.splice(upcomingIdx, 1);
        state[meetingType].past.unshift(meeting);
      } else if (pastIdx >= 0 && isUpcoming) {
        state[meetingType].past.splice(pastIdx, 1);
        state[meetingType].upcoming.unshift(meeting);
      } else {
        state[meetingType].past[pastIdx] = meeting;
      }
    },
    deleteMeeting(state: any, {meetingType, meetingId}: any) {
      const upcomingIdx = state[meetingType].upcoming.findIndex((m: any) => m.id === meetingId);
      if (upcomingIdx >= 0) {
        state[meetingType].upcoming.splice(upcomingIdx, 1);
      } else {
        const pastIdx = state[meetingType].past.findIndex((m: any) => m.id === meetingId);
        state[meetingType].past.splice(pastIdx, 1);
      }
    },
    deleteFile(state: any, {meetingType, fileId, meetingId}: any) {
      const upcomingIdx = state[meetingType].upcoming.findIndex((m: any) => m.id === meetingId);
      if (upcomingIdx >= 0) {
        state[meetingType].upcoming[upcomingIdx].files.splice(
          state[meetingType].upcoming[upcomingIdx].files.findIndex((f: any) => f.id === fileId));
      } else {
        const pastIdx = state[meetingType].past.findIndex((m: any) => m.id === meetingId);
        state[meetingType].past[pastIdx].files.splice(
          state[meetingType].past[pastIdx].files.findIndex((f: any) => f.id === fileId));
      }
    },
    addFile(state: any, {meetingType, file, meetingId}: any) {
      const upcomingIdx = state[meetingType].upcoming.findIndex((m: any) => m.id === meetingId);
      if (upcomingIdx >= 0) {
        state[meetingType].upcoming[upcomingIdx].files.push(file);
      } else {
        const pastIdx = state[meetingType].past.findIndex((m: any) => m.id === meetingId);
        state[meetingType].past[pastIdx].files.push(file);
      }
    },
    sortMeetings(state: any, meetingType: number) {
      state[meetingType].upcoming.sort((a: any, b: any) => {
        const sortDateA = a.end_date ? a.end_date : a.start_date;
        const sortDateB = b.end_date ? b.end_date : b.start_date;
        return moment(sortDateA).isAfter(sortDateB) ? 1 : -1;
      });
      state[meetingType].past.sort((a: any, b: any) => {
        const sortDateA = a.end_date ? a.end_date : a.start_date;
        const sortDateB = b.end_date ? b.end_date : b.start_date;
        return moment(sortDateA).isBefore(sortDateB) ? 1 : -1;
      });
    },
  },
  actions: {
    fetchMeetings({commit}: any, meetingType: number) {
      axios.get("/meetings/upcoming", {params: {meeting_type_id: meetingType}}).then((res) => {
        commit("setUpcomingMeetings", {meetingType, data: res.data});
        return axios.get("/meetings/past", {params: {meeting_type_id: meetingType}});
      }).then((res) => {
        commit("setPastMeetings", {meetingType, data: res.data});
      });
    },
    addMeeting({commit}: any, {meetingType, meeting}: any) {
      return new Promise((resolve, reject) => {
        axios.post("/controlpanel/meetings", {meeting_type_id: meetingType, ...meeting}).then((res) => {
          commit("addMeeting", {meetingType, meeting: res.data});
          commit("sortMeetings", meetingType);
          resolve();
        }).catch(reject);
      });
    },
    editMeeting({commit}: any, {meetingType, meeting}: any) {
      return new Promise((resolve, reject) => {
        axios.patch(`/controlpanel/meetings/${meeting.id}`, meeting).then(() => {
          commit("editMeeting", {meetingType, meeting});
          commit("sortMeetings", meetingType);
          resolve();
        }).catch(reject);
      });
    },
    deleteMeeting({commit}: any, {meetingType, meetingId}: any) {
      return new Promise((resolve, reject) => {
        axios.delete(`/controlpanel/meetings/${meetingId}`).then(() => {
          commit("deleteMeeting", {meetingType, meetingId});
          resolve();
        }).catch(reject);
      });
    },
    deleteFile({commit}: any, {meetingType, file, meetingId}: any) {
      return new Promise((resolve, reject) => {
        axios.delete(`/controlpanel/meetings/${meetingId}/files/${file.id}`).then(() => {
          commit("deleteFile", {meetingType, fileId: file.id, meetingId});
          resolve();
        }).catch(reject);
      });
    },
    addFile({commit}: any, {meetingType, meetingId, formData}: any) {
      return new Promise(((resolve, reject) => {
        axios.post(`/controlpanel/meetings/${meetingId}/files`, formData).then((res) => {
          commit("addFile", {meetingType, file: res.data, meetingId});
          resolve(res.data);
        });
      }));
    },
  },
};
