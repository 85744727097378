









































import {Vue} from "vue-property-decorator";

export default class Sidebar extends Vue {

  public logout = () => {
    this.$store.dispatch("authLogout").then(() => {
      this.$router.push("/login");
    });
  }

  public hasRight = (rightId: number) => {
      return this.$store.getters.checkRight(rightId);
  }

  get downloadsActive() {
    return this.$route.name === "public" || this.$route.name === "other" || this.$route.name === "software";
  }
}
