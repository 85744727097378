import Vue from "vue";
import Vuex from "vuex";

import auth from "./store/auth";
import meetings from "./store/meetings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    meetings,
  },
});
