import store from "./store";
import Vue from "vue";
import App from "./App.vue";
import {router} from "./router";
import axios from "axios";
import VModal from "vue-js-modal";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || "http://localhost:3000";

Vue.config.productionTip = false;
Vue.use(VModal);

store.dispatch("refreshToken").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
